/* eslint-disable no-unused-vars */
import { useEffect, useRef } from "react";

export function useOnDraw(onDraw) {
    const canvasRef = useRef(null);
    const prevPointRef = useRef(null);
    const isDrawingRef = useRef(false);

    const mouseMoveListenerRef = useRef(null);
    const mouseUpListenerRef = useRef(null);

    useEffect(() => {
        function initMouseMoveListener() {
            const mouseMoveListener = (e) => {
                if (isDrawingRef.current) {
                    const point = computePointInCanvas(e.clientX, e.clientY);
                    console.log(point);
                    const ctx = canvasRef.current.getContext("2d");
                    if (onDraw) {
                        onDraw(ctx, point, prevPointRef.current);
                        prevPointRef.current = point;
                    }
                }
            };
            mouseMoveListenerRef.current = mouseMoveListener;
            window.addEventListener("pointermove", mouseMoveListener);
        }

        function initMouseUpListener() {
            const listener = () => {
                isDrawingRef.current = false;
                prevPointRef.current = null;
            };
            canvasRef.current.addEventListener("pointerup", listener);
            mouseUpListenerRef.current = listener;
        }

        function computePointInCanvas(clientX, clientY) {
            if (canvasRef.current) {
                const boudingRect = canvasRef.current.getBoundingClientRect();

                return {
                    x: clientX - boudingRect.left,
                    y: clientY - boudingRect.top,
                };
            } else {
                return null;
            }
        }

        function removeListeners() {
            if (mouseMoveListenerRef.current) {
                window.removeEventListener(
                    "pointermove",
                    mouseMoveListenerRef.current
                );
            }
            if (mouseUpListenerRef.current) {
                window.removeEventListener(
                    "pointerup",
                    mouseUpListenerRef.current
                );
            }
        }
        initMouseMoveListener();
        initMouseUpListener();
        return () => {
            removeListeners();
        };
    }, [onDraw]);

    function setCanvasRef(ref) {
        canvasRef.current = ref;
    }

    function onMouseDown() {
        isDrawingRef.current = true;
        console.log(isDrawingRef.current);
    }

    return { setCanvasRef, onMouseDown };
}

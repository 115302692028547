import styles from "./Draw.module.scss";

import Canvas from "./Canvas";

function Draw() {
    return (
        <div className={`flex-row-center-all ${styles.divDraw}`}>
            <Canvas width={"700px"} height={"500px"} />
        </div>
    );
}

export default Draw;

import { useState, useContext } from "react";
import { useLoaderData } from "react-router-dom";
import { AuthContext } from "../../context";
import { UrlContext } from "../../context/UrlContext";

import { signin, signout } from "../../apis/auth";

function AuthProvider({ children }) {
    const initialUser = useLoaderData();
    const URL = useContext(UrlContext);

    const [user, setUser] = useState(initialUser);
    const [verifiedEmail, setVerifiedEmail] = useState();

    async function login(credentials) {
        const newUser = await signin(credentials, `${URL}auth/signin`);
        console.log(!newUser.local.emailVerified);
        if (!newUser.local.emailVerified) {
            document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            setVerifiedEmail(newUser.local.emailVerified);
        } else {
            setVerifiedEmail(newUser.local.emailVerified);
            setUser(newUser);
        }
    }

    async function logout() {
        await signout();
        setUser(null);
        setVerifiedEmail(null);
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                login,
                logout,
                URL,
                verifiedEmail,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;

import styles from "./App.module.scss";

import { Outlet } from "react-router-dom";
import AuthProvider from "./components/AuthProvider/AuthProvider";
import { Suspense } from "react";

function App() {
    return (
        <div className={`${styles.App}`}>
            <AuthProvider>
                <Suspense>
                    <Outlet />
                </Suspense>
            </AuthProvider>
        </div>
    );
}

export default App;

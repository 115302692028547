import axios from "axios";

// Config Url
const instance = axios.create({
    // baseURL: "https://www.cinq-onze.com/",
});

// Config Token in Anthorization-Header
instance.interceptors.request.use(
    function (config) {
        const arrayCookie = document.cookie.split("=");
        const token = arrayCookie[1];
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default instance;

import styles from "./Canvas.module.scss";

import { useOnDraw } from "../../hooks/useOnDraw";

function Canvas({ width, height }) {
    const { setCanvasRef, onMouseDown } = useOnDraw(onDraw);

    function onDraw(ctx, point, prevPoint) {
        drawLine(prevPoint, point, ctx, "#000000", 5);
    }

    function drawLine(start, end, ctx, color, width) {
        start = start ?? end;
        ctx.beginPath();
        ctx.lineWidth = width;
        ctx.strokeStyle = color;
        ctx.moveTo(start.x, start.y);
        ctx.lineTo(end.x, end.y);
        ctx.stroke();

        ctx.fillStyle = color;
        ctx.beginPath();
        ctx.arc(start.x, start.y, 2, 0, 2 * Math.PI);
        ctx.fill();
    }
    return (
        <canvas
            className={`${styles.canvas}`}
            width={width}
            onPointerDown={onMouseDown}
            height={height}
            ref={setCanvasRef}
        ></canvas>
    );
}

export default Canvas;

// import axios from "axios";
import styles from "./Test.module.scss";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import instance from "../../instance";
import logo from "../../assets/images/designMetro/nomLogo.png";

const mySwal = withReactContent(Swal);

function Test() {
    async function handleTest() {
        mySwal.fire({
            icon: "success",
            title: "Inscription réussie!",
            text: "Il te reste à confirmer ton adresse mail, un lien t'a été envoyé...",
            colorIcon: "#7f3354",
            width: "30%",
        });
    }
    async function handleGame() {
        // await instance.patch("https://localhost:3001/cheatPlayer/addGame");
    }
    return (
        <div className={`flex-row-center-all ${styles.div}`}>
            {/* <h1>Je suis la page test</h1>
            <button onClick={handleTest}>test</button>
            <div className={`${styles.testTaille}`}></div>

            <button onClick={handleGame}>test addgame</button> */}
            <img
                className={`${styles.titleImage}`}
                src={logo}
                alt="logo CinqOnze"
            />
        </div>
    );
}

export default Test;

export async function signin(credentials, url) {
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        });
        if (response.ok) {
            const data = await response.json();
            const token = data.token;
            document.cookie = "token=" + token;
            return data.user;
        } else {
            throw new Error("Oops une erreur est survenue");
        }
    } catch (error) {
        console.log(error);
    }
}

export async function signout() {
    document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

import { createBrowserRouter } from "react-router-dom";

import { currentUserLoader } from "./loaders/currentUserLoader";
import { articlesLoader } from "./loaders/articlesLoader";

import { lazy } from "react";

import App from "./App";

import Draw from "./pages/Draw/Draw";

import Test from "./pages/Test/Test";

const GamesMenu = lazy(() => import("./pages/Games/GamesMenu/GamesMenu"));
const NewArticle = lazy(() =>
    import("./pages/Blog/BlogArticle/NewArticle/NewArticle")
);
const TestPage = lazy(() => import("./pages/TestPage/TestPage"));
const AdminPage = lazy(() => import("./pages/AdminPage/AdminPage"));
const Container = lazy(() => import("./components/Container/Container"));
const Welcome = lazy(() => import("../src/pages/Welcome/Welcome"));
const Connexion = lazy(() => import("./pages/Connexion/Connexion/Connexion"));
const ConnexionOutlet = lazy(() =>
    import("./pages/Connexion/ConnexionOutlet/ConnexionOutlet")
);
const EmailOk = lazy(() => import("./pages/Connexion/EmailOk/EmailOk"));
const ForgotPassword = lazy(() =>
    import("./pages/Connexion/ForgotPassword/ForgotPassword")
);
const ResetPassword = lazy(() =>
    import("./pages/Connexion/ResetPassword/ResetPassword")
);
const Inscription = lazy(() => import("../src/pages/Inscription/Inscription"));
const Blog = lazy(() => import("./pages/Blog/Blog"));
const Profil = lazy(() => import("./pages/Profil/Profil"));

const ProtectedRouteCurrentUser = lazy(() =>
    import("./components/ProtectedRouteCurrentUser/ProtectedRouteCurrentUser")
);
const ProtectedRouteAdmin = lazy(() =>
    import("./components/ProtectedRouteAdmin/ProtectedRouteAdmin")
);
const ProtectedRouteCheat = lazy(() =>
    import(
        "./components/ProtectedRouteEnigme/ProtectedRouteCheat/ProtectedRouteCheat"
    )
);

const Cesar = lazy(() => import("./pages/Blog/BlogArticle/Cesar/Cesar"));
const Atbash = lazy(() => import("./pages/Blog/BlogArticle/Atbash/Atbash"));
const BlogMenu = lazy(() => import("./pages/Blog/BlogMenu/BlogMenu"));
const Cheat = lazy(() => import("./pages/Games/ListGames/Cheat/Cheat"));
const Enigmes = lazy(() => import("./pages/Games/GamesMenu/GamesMenu"));
const Games = lazy(() => import("./pages/Games/Games"));

const Model = lazy(() => import("./pages/Enquetes/Model/Model"));

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        loader: currentUserLoader,
        children: [
            {
                path: "/",
                element: <Container />,
                children: [
                    {
                        path: "/",
                        loader: articlesLoader,
                        element: <Welcome />,
                    },

                    {
                        path: "/connexion",
                        element: <ConnexionOutlet />,
                        children: [
                            {
                                path: "/connexion",
                                element: <Connexion />,
                            },
                            {
                                path: "/connexion/emailok",
                                element: <EmailOk />,
                            },
                            {
                                path: "/connexion/forgotpassword",
                                element: <ForgotPassword />,
                            },
                            {
                                path: "/connexion/resetpassword/:userId/:token",
                                element: <ResetPassword />,
                            },
                        ],
                    },

                    {
                        path: "/inscription",
                        element: <Inscription />,
                    },
                    {
                        path: "/test",
                        element: <Test />,
                    },
                    {
                        path: "/profil",
                        element: (
                            <ProtectedRouteCurrentUser>
                                <Profil />
                            </ProtectedRouteCurrentUser>
                        ),
                    },
                    {
                        path: "/admin",
                        element: (
                            <ProtectedRouteAdmin>
                                <AdminPage />
                            </ProtectedRouteAdmin>
                        ),
                    },
                    {
                        path: "/jeux",
                        element: <Games />,
                        children: [
                            {
                                path: "/jeux",
                                element: <GamesMenu />,
                            },
                            {
                                path: "/jeux/Fraude",
                                element: <Cheat />,
                            },
                        ],
                    },
                    {
                        path: "/blog",
                        element: <Blog />,

                        children: [
                            {
                                path: "/blog",
                                loader: articlesLoader,
                                element: <BlogMenu />,
                            },
                            {
                                path: "/blog/Cesar",
                                element: <Cesar />,
                            },
                            {
                                path: "/blog/Atbash",
                                element: <Atbash />,
                            },
                            {
                                path: "/blog/Test",
                                element: <NewArticle />,
                            },
                        ],
                    },
                    {
                        path: "/enigmes",
                        element: <Enigmes />,

                        children: [
                            {
                                path: "/enigmes/fraude",
                                element: <Cheat />,
                            },
                        ],
                    },
                ],
            },
            {
                path: "/enquete",
                children: [
                    {
                        path: "/enquete/fraude",
                        element: (
                            <ProtectedRouteCheat>
                                <Model />
                            </ProtectedRouteCheat>
                        ),
                    },
                ],
            },
            {
                path: "/test",
                element: <TestPage />,
            },
            {
                path: "/draw",
                element: <Draw />,
            },
            {
                path: "/draw",
                element: <Draw />,
            },
        ],
    },
]);

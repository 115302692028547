import axios from "axios";

export async function articlesLoader() {
    try {
        const response = await axios.get(
            "https://cinqonze.com/article/allFind"
        );
        return response.data;
    } catch (e) {
        console.log(e);
    }
}

import instance from "../instance";

export async function userConnect() {
    try {
        const response = await instance.get(
            `https://cinqonze.com/auth/isconnected`
        );

        return response.data;
    } catch (error) {
        console.log(error);
    }
}
